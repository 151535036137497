
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import MixinsTable from "@/mixins/single/table";
import MixinsScroll from "@/mixins/single/scroll";
import ProfileService from "@/services/profile/profile.service";
import { LayoutType, RouteMeta } from "@/router";
import { AppBarMenuItem, UpdateEventType } from "@/types";
import ProfileListItemComponent from "@/components/profile/ListItemComponent.vue";
import { cloneDeep } from "lodash";
import MixinsAppBarMenu from "@/mixins/single/app-bar-menu";
import CategoryService from "@/services/category/category.service";
import DashboardService from "@/services/dashboard/dashboard.service";

export default mixins(MixinsPageForm, MixinsAppBarMenu, MixinsTable, MixinsScroll).extend({
  name: "Directory",
  components: { ProfileListItemComponent },
  data: () => ({
    table: {
      body: document.documentElement,
      defaultColumns: [],
      firstLoading: true,
      loading: false,
    },
    prop: null as any,
    filter: {
      selected: null as any,
      selectedItem: null as any,
      items: [] as any,
    },
  }),
  mounted() {
    const meta = this.$route.meta as RouteMeta;
    const layoutList = cloneDeep(meta.layoutList) as LayoutType[];
    layoutList.push(LayoutType.APP_BAR);
    this.prop = {
      layoutList: layoutList,
      appBarMenu: meta.appBarMenu,
    };

    this.table.request.orderColumnName = "koName";
    this.table.request.order = "asc";

    // 테이블 초기화
    this.initTable({
      service: ProfileService,
      serviceFunctionKey: "getTable",
      itemHeight: 100,
    });

    this.$nextTick(async () => {
      const jgCategoryList = await CategoryService.getJgList();
      const jgCategoryCountList = (await DashboardService.getJgCategoryCountList()) as any;
      const countMap = {} as any;
      let totalCount = 0;
      jgCategoryCountList.forEach((jgCategoryCount) => {
        countMap[jgCategoryCount.id] = jgCategoryCount.count;
        totalCount += jgCategoryCount.count;
      });
      this.filter.items = [] as any;
      this.addFilterItems(null, "전체", totalCount);

      jgCategoryList.forEach((jgCategory) => {
        let count = countMap[jgCategory.id];
        this.addFilterItems(jgCategory.id, jgCategory.name, count);
      });
    });
  },
  watch: {
    "scroll.scrollTop"(scrollTop) {
      // console.log("scrollTop : ", scrollTop);
      this.scrollTop = scrollTop;
    },
    "filter.selected"(selected) {
      this.changedFilter(selected);
    },
  },
  activated() {
    const event = this.getPageUpdateEvent();
    if (event != null) {
      if (event.result === UpdateEventType.UPDATE) {
        //console.log("event : ", event);
        if (event.path.indexOf("search") > -1) {
          this.updateTableItem("id", event.item);
        } else {
          this.addTableItem("id", event.item);
        }
      } else if (event.result === UpdateEventType.DELETE) {
        this.deleteTableItem("id", event.item);
      } else {
        console.log("unknown event : ", event);
      }
    }
  },
  methods: {
    addFilterItems(id, name, count) {
      if (count == null) count = 0;
      if (count > 0) {
        this.filter.items.push({ id: id, text: `${name}(${count})` });
      } else {
        console.log("name : ", name);
        this.filter.items.push({ id: id, text: name });
      }
    },
    appBarMenuEvent(menu: AppBarMenuItem) {
      if (menu.id === "search") {
        const query = {} as any;
        if (this.filter.selectedItem.id != null) {
          query.jgCategoryId = this.filter.selectedItem.id;
        }
        this.$router.push({
          path: "/profile/search",
          query: query,
        });
      } else {
        console.log("unknown id : ", menu.id);
      }
    },
    changedFilter(selected) {
      if (selected != null) {
        const selectedItem = this.filter.items[selected];
        if (selectedItem) {
          this.filter.selectedItem = selectedItem;

          // 테이블 정보 가져오기
          const searchColumns = {} as any;
          if (selectedItem.id != null) {
            searchColumns.jgCategoryId = selectedItem.id;
          }
          this.loadTable(searchColumns);
        } else {
          console.log("not found item : ", selected);
        }
      }
    },
  },
});
