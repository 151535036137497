
import mixins from "vue-typed-mixins";
import MixinsModalForm from "@/mixins/modal-form";
import { UpdateEventType } from "@/types";
import UpdateEvent from "@/models";

import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import store from "@/store";
import core from "@/core";

export default mixins(MixinsModalForm).extend({
  name: "ImageCropperModal",

  components: {
    VueCropper,
  },
  props: {
    properties: {
      type: Object,
      default: () => {
        return {
          visible: false,
          params: {
            filename: "",
            dataUri: "",
          },
          // 업데이트 이벤트 처리용 변수
          updateEvent: null as UpdateEvent | null,
        };
      },
    },
  },
  data: () => ({
    isMobileSize: false,
    app: store.state.app,
    bodyHeight: 500,
    imageStyle: {
      minHeight: "500px",
      maxHeight: "500px",
    },
  }),
  mounted() {
    this.$nextTick(async () => {
      this.windowResize();
    });
  },
  watch: {
    "app.size"() {
      this.windowResize();
    },
  },
  methods: {
    windowResize() {
      const app = this.app;
      const size = app.size;

      const isMobileSize = (this.isMobileSize = size.width < 1265);
      let bodyHeight = size.height - 52;

      if (!isMobileSize) {
        bodyHeight = size.height / 2 - 52;
      }
      this.bodyHeight = bodyHeight;
      if (this.$refs.body) {
        const $el = this.$refs.body as any;
        $el.style.minHeight = `${bodyHeight}px`;
        $el.style.maxHeight = `${bodyHeight}px`;
      }
      this.imageStyle.minHeight = bodyHeight + "px";
      this.imageStyle.maxHeight = bodyHeight + "px";
    },
    async submit() {
      const cropper = this.$refs.cropper as any;
      const canvas: HTMLCanvasElement = cropper.getCroppedCanvas();
      const dataUri = canvas.toDataURL("image/jpg");
      const result = {
        filename: this.properties.params.filename,
        dataUri: dataUri,
        blob: core.utils.image.dataURItoBlob(dataUri),
      };
      this.close(UpdateEventType.CONFIRM, result);
    },
  },
});
