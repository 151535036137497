
import mixins from "vue-typed-mixins";
import store from "@/store";
import Constant from "@/store/constant";
import MixinsCommon from "@/mixins/single/common";
import core from "@/core";

export default mixins(MixinsCommon).extend({
  name: "NavigationDrawer",
  components: {},
  data: () => ({
    app: store.state.app,
    auth: store.state.auth,
  }),
  computed: {
    isManagerRoleHigher() {
      return this.$store.getters["auth/isManagerRoleHigher"];
    },
    isAdminRole() {
      return this.$store.getters["auth/isAdminRole"];
    },
    Constant() {
      return Constant;
    },
    title() {
      return this.$store.getters["topToolbar/title"];
    },
    dark() {
      return this.$store.getters["topToolbar/dark"];
    },
    clazz() {
      return this.$store.getters["topToolbar/clazz"];
    },
    elevation() {
      return this.$store.getters["topToolbar/elevation"];
    },
    color() {
      return this.$store.getters["topToolbar/color"];
    },
  },
  mounted() {
    this.changedShowNav();
  },
  watch: {
    "app.showNav"(show) {
      this.changedShowNav();
    },
  },
  methods: {
    changedShowNav() {
      const show = this.app.showNav;
      if (show) {
        if (!this.app.isMobileSize) {
          document.documentElement.style.setProperty("--nav", `300px`);
        } else {
          document.documentElement.style.setProperty("--nav", `0px`);
        }
      } else {
        document.documentElement.style.setProperty("--nav", `0px`);
      }
    },
    async logout() {
      const result = await core.alert.show({
        title: "확인",
        body: "현재 인증된 정보가 해제됩니다.<br>계속 진행하시겠습니까?",
        showCancelButton: true,
      });
      if (result === "confirm") {
        await this.$router.push("/logout");
      }
    },
  },
});
