import korea from "vee-validate/dist/locale/ko.json";

class Constant {
  appName = "한국농업기계학회 인명록";
  validate = {
    language: {
      korea: korea,
    },
  };
  url = "http://127.0.0.1:3000";
  param = {
    login: [],
  };
}

export default new Constant();
