import Vue from "vue";
import { RouteMeta } from "@/router";
import { AppBarMenu, AppBarMenuItem } from "@/types";
import core from "@/core";

/**
 * App bar menu 공통 처리
 */
export default Vue.extend({
  data: () => ({
    appBarMenu: {
      metaMenu: {} as AppBarMenu,
      iconMenuItems: {},
      moreMenuItems: {},
      watchList: [] as any,
    },
  }),
  created() {
    const meta = this.$route.meta as RouteMeta;
    const appBarMenu = meta.appBarMenu;
    if (appBarMenu != null) {
      this.appBarMenu.metaMenu = appBarMenu;
      ["icon", "more"].some((key) => {
        const parentMenu = appBarMenu[key];
        if (parentMenu) {
          parentMenu.visibleCount = 0;
          const list = parentMenu.list;
          if (list) {
            list.some((menu) => {
              if (menu.visible) {
                parentMenu.visibleCount++;
              }
              // console.log("watch menu : ", menuItem.id, this.$vnode.tag);
              this.appBarMenu.watchList.push(
                this.$store.watch(
                  () => menu.clickEvent,
                  () => {
                    this.appBarMenuEvent(menu);
                  }
                )
              );

              this.appBarMenu.watchList.push(
                this.$store.watch(
                  () => menu.visible,
                  (visible) => {
                    if (visible) {
                      parentMenu.visibleCount++;
                    } else {
                      parentMenu.visibleCount--;
                    }
                    parentMenu.visible = parentMenu.visibleCount > 0;
                  }
                )
              );
              let menuItems = null as any;
              if (key === "icon") {
                menuItems = this.appBarMenu.iconMenuItems;
              } else if (key === "more") {
                menuItems = this.appBarMenu.moreMenuItems;
              } else {
                console.log("unknown key : ", key);
                return;
              }

              if (menuItems[menu.id]) {
                console.log("duplicate id : {}", menu.id, menu);
              }
              menuItems[menu.id] = menu;
            });
          }
          parentMenu.visible = parentMenu.visibleCount > 0;
        }
      });
    }
  },
  activated() {
    // console.log("activated");
    const appBarMenu = this.appBarMenu.metaMenu;
    if (appBarMenu && this.appBarMenu.watchList.length === 0) {
      ["icon", "more"].some((key) => {
        const parentMenu = appBarMenu[key];
        if (parentMenu) {
          parentMenu.visibleCount = 0;
          const list = parentMenu.list;
          if (list) {
            list.some((menu) => {
              if (menu.visible) {
                parentMenu.visibleCount++;
              }
              // console.log("watch menu : ", menuItem.id, this.$vnode.tag);
              this.appBarMenu.watchList.push(
                this.$store.watch(
                  () => menu.clickEvent,
                  () => {
                    this.appBarMenuEvent(menu);
                  }
                )
              );

              this.appBarMenu.watchList.push(
                this.$store.watch(
                  () => menu.visible,
                  (visible) => {
                    if (visible) {
                      parentMenu.visibleCount++;
                    } else {
                      parentMenu.visibleCount--;
                    }
                    parentMenu.visible = parentMenu.visibleCount > 0;
                  }
                )
              );
            });
          }
          parentMenu.visible = parentMenu.visibleCount > 0;
        }
      });
    }
  },
  deactivated() {
    //console.log("deactivated");
    this.appBarMenu.watchList.forEach((watch) => {
      watch();
    });
    this.appBarMenu.watchList = [];
  },
  destroyed() {
    //console.log("destroyed");
    this.appBarMenu.watchList.forEach((watch) => {
      watch();
    });
    this.appBarMenu.watchList = [];
  },
  methods: {
    appBarMenuEvent(menu: AppBarMenuItem) {
      console.log("appBarMenuEvent(menu: AppBarMenuItem) => function not declared : ", menu);
    },
    appBarChangeMenuVisible(id: string, visible: boolean) {
      let menu = null as AppBarMenuItem | null;
      const appBarMenu = this.appBarMenu;
      [appBarMenu.iconMenuItems, appBarMenu.moreMenuItems].some((menuItems) => {
        menu = menuItems[id];
        if (menu != null) {
          return true;
        }
      });
      if (menu == null) {
        console.log("not found menu : ", id);
        return;
      }
      menu.visible = visible;
    },
    appBarChangeMenuIcon(id: string, icon: string) {
      let menu = null as AppBarMenuItem | null;
      const appBarMenu = this.appBarMenu;
      [appBarMenu.iconMenuItems, appBarMenu.moreMenuItems].some((menuItems) => {
        menu = menuItems[id];
        if (menu != null) {
          return true;
        }
      });
      if (menu == null) {
        console.log("not found menu : ", id);
        return;
      }
      menu.icon = icon;
    },
    appBarChangeMenuBadge(id: string, content: string) {
      let menu = null as AppBarMenuItem | null;
      const appBarMenu = this.appBarMenu;
      [appBarMenu.iconMenuItems, appBarMenu.moreMenuItems].some((menuItems) => {
        menu = menuItems[id];
        if (menu != null) {
          return true;
        }
      });
      if (menu == null) {
        console.log("not found menu : ", id);
        return;
      }
      menu.badge = core.utils.validate.isNotBlank(content);
      menu.badgeContent = content;
    },
  },
});
