
import mixins from "vue-typed-mixins";
import MixinsModal from "@/mixins/modal";
import UpdateEvent from "@/models";
import { UpdateEventType } from "@/types";
import store from "@/store";

export default mixins(MixinsModal).extend({
  name: "SelectPhotoModal",
  props: {
    visibleDefaultImage: {
      type: Boolean,
      default: true,
    },
    properties: {
      type: Object,
      default: () => {
        return {
          visible: false,
          updateEvent: null as UpdateEvent | null,
        };
      },
    },
  },
  data: () => ({
    app: store.state.app,
  }),
  mounted() {
    if (!this.visibleDefaultImage && !this.app.isMobile) {
      setTimeout(() => {
        this.select("image");
      }, 0);
    }
  },
  methods: {
    select(item) {
      // console.log("item : ", item);
      this.close(UpdateEventType.CONFIRM, item);
    },
  },
});
