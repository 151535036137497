interface MobileCall {
  cmd: string;
  value?: any;
}

export default class CoreMobile {
  call(params: MobileCall) {
    if (this.isApp()) {
      const platform = this.platform();
      if (platform === "android") {
        const strParam = JSON.stringify(params);
        //console.log("android call : ", strParam);
        (window as any).android.call(strParam);
      } else if (platform === "ios") {
        const strParam = JSON.stringify(params);
        //console.log("android call : ", strParam);
        (window as any).webkit.messageHandlers.call.postMessage(strParam);
      } else {
        console.log("not support platform. ", platform);
      }
    } else {
      console.log("is not mobile app");
    }
  }

  platform(): string {
    const userAgent = navigator.userAgent.toLowerCase();
    let platform = "";
    if (userAgent.indexOf("android") > -1) {
      platform = "android";
    } else if (
      userAgent.indexOf("iphone") > -1 ||
      userAgent.indexOf("ipad") > -1 ||
      userAgent.indexOf("ipod") > -1
    ) {
      // IOS
      platform = "ios";
    } else if (userAgent.indexOf("windows") > -1) {
      platform = "windows";
    } else {
      // 윈도우, 아이폰, 안드로이드 외
      platform = "other";
    }
    return platform;
  }

  isApp(): boolean {
    const userAgent = navigator.userAgent.toLowerCase();
    return userAgent.indexOf("scc") > -1;
  }

  getSmsLink(phoneNumber: string, content: string): string {
    const platform = this.platform();
    if (platform === "android") {
      return "sms:" + phoneNumber + "?body=" + encodeURIComponent(content);
    } else if (platform === "ios") {
      return "sms:" + phoneNumber + "&body=" + encodeURIComponent(content);
    } else {
      return "";
    }
  }

  getGeoLink(query: string): string {
    const platform = this.platform();
    if (platform === "android") {
      return "geo:?q=" + query;
    } else if (platform === "ios") {
      return "maps:?q=" + query;
    } else {
      return "";
    }
  }

  getTelLink(phone) {
    const phoneNumber = phone.toString().replace(/[^0-9]/g, "");
    return "tel:" + phoneNumber;
  }

  getMailLink(email) {
    return "mailto:" + email;
  }
}
