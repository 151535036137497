import core from "@/core";
import mixins from "vue-typed-mixins";
import MixinsCommon from "@/mixins/single/common";
import { UpdateEventType } from "@/types";
import UpdateEvent from "@/models";

/**
 * 페이지 공통 처리
 */
export default mixins(MixinsCommon).extend({
  data: () => ({}),
  mounted() {
    if (document.documentElement.scrollTop > 0) {
      document.documentElement.scrollTop = 0;
    }
  },
  activated() {},
  methods: {
    getPageUpdateEvent() {
      const event = this.updateEvent;
      this.updateEvent = null;
      return event;
    },
    getComponentUpdateEvent(modal: any): UpdateEvent | null {
      if (modal == null) {
        console.log("invalid param");
        return null;
      }
      const event = modal.updateEvent;
      modal.updateEvent = null;
      return event;
    },
    goBack(type?: UpdateEventType, item?: any) {
      if (type != null) {
        this.updatePreVmEvent(type, item);
      }
      const $this = this as any;
      if ($this.backButton != null) {
        $this.backButton.changed = false;
      }

      if (window.history.length > 1) {
        this.$router.back();
      } else {
        this.$router.push("/");
      }
    },
    hasPreVmPageStack() {
      const vm = this.getPreStackVm();
      return vm != null && vm.$vnode.data.keepAlive;
    },
    updatePreVmEvent(type: UpdateEventType, item?: any) {
      const vm = this.getPreStackVm();
      // console.log("vm : ", vm);
      if (vm != null) {
        if (vm.$vnode.data.keepAlive) {
          vm.updateEvent = new UpdateEvent(type, this.$route.path, item);
        } else {
          (this as any).$eventBus.$emit(
            "updateEvent",
            new UpdateEvent(type, this.$route.path, item)
          );
        }
      } else {
        console.log("not exists pre vm");
      }
    },
    replaceState(urlParams?: any) {
      if (urlParams == null) urlParams = {};
      if (this.isNotBlank(this.$route.query.stackKey)) {
        urlParams.stackKey = this.$route.query.stackKey;
      }
      const length = Object.keys(urlParams).length;
      const url = this.$route.path + (length > 0 ? "?" + core.http.objToUrlParams(urlParams) : "");
      history.replaceState("", "", url);
    },
    getPreStackVm() {
      return (this as any).$pageStack.getPreStackInstance(this);
    },
    async notFound() {
      await core.alert.show({
        title: "알림",
        body: "잘못된 요청입니다.",
        allowBackCloseEvent: false,
      });
      this.goBack(UpdateEventType.RELOAD);
    },
  },
});
