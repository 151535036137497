
import core from "@/core";
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import { UserModel } from "@/models/user/user.model";
import UserService from "@/services/user/user.service";
import { cloneDeep } from "lodash";
import UpdateEvent from "@/models";
import { UpdateEventType } from "@/types";

export default mixins(MixinsPageForm).extend({
  name: "UserMyInfo",
  data: () => ({
    view: {
      username: "",
      email: "",
      phone: "",
    },
    form: {
      email: "",
      emailVerifyToken: "",
      emailCertificationNumber: "",
      name: "",
      phone: "",
      phoneCertificationNumber: "",
      phoneVerifyToken: "",
    },
    emailAuth: {
      visible: {
        btn: false,
        help: false,
      },
      verified: false,
      waitTimeMillis: 600000,
      remainingTimeMin: "10",
      date: null as Date | null,
      interval: null as number | null,
      expired: false,
      processing: false,
    },
    phoneAuth: {
      visible: {
        btn: false,
        help: false,
      },
      verified: false,
      waitTimeMillis: 600000,
      remainingTimeMin: "10",
      date: null as Date | null,
      interval: null as number | null,
      expired: false,
      processing: false,
    },
    user: null as UserModel | null,
    processing: false,
    // iconSubMenu: { edit: null as Menu | null },
    modal: {
      selectCamera: {
        visible: false,
        updateEvent: null as UpdateEvent | null,
      },
    },

    formConvertField: {
      username: "user_username",
      name: "user_name",
      confirmPassword: "user_confirmPassword",
      email: "user_email",
      emailCertificationNumber: "user_emailCertificationNumber",
      phone: "user_phone",
      phoneCertificationNumber: "user_phoneCertificationNumber",
    },
  }),
  mounted() {
    // 페이지 로딩 후 호출
    this.$nextTick(async () => {
      const user: UserModel = (this.user = await this.$store.getters["auth/user"]());
      //console.log("user : ", user);
      this.view.username = user.username;
      this.view.phone = this.phoneFormat(user.phone);
      this.view.email = user.email;

      this.form.phone = this.phoneFormat(user.phone);
      this.form.email = user.email;
      this.form.name = user.name;
    });
  },
  beforeRouteLeave(to, from, next) {
    [this.emailAuth].forEach((auth) => {
      if (auth.interval != null) {
        clearInterval(auth.interval);
        auth.interval = null;
      }
    });
    next();
  },
  watch: {
    "form.phone"() {
      this.form.phone = this.phoneFormat(this.form.phone);
      this.phoneAuth.visible.help = false;
      this.form.phoneVerifyToken = "";
      this.form.phoneCertificationNumber = "";
      if (this.isBlank(this.form.phone)) {
        this.phoneAuth.visible.btn = false;
      } else {
        this.phoneAuth.visible.btn = this.form.phone != this.view.phone;
      }
    },
    "form.email"() {
      this.form.emailVerifyToken = "";
      this.emailAuth.visible.help = false;
      this.form.emailCertificationNumber = "";
      if (this.isBlank(this.form.email)) {
        this.emailAuth.visible.btn = false;
      } else {
        this.emailAuth.visible.btn = this.form.email != this.view.email;
      }
    },
    "form.emailCertificationNumber"(val) {
      if (this.isNotBlank(val) && val.length === 6) {
        // 이메일 인증번호 검증
        console.log("이메일 인증번호 검증");
        this.processing = true;
        this.resetValidate();
        setTimeout(async () => {
          const emailVerify = await this.checkEmailVerify();
          // console.log("emailVerify : ", emailVerify);
          this.processing = false;
          if (!emailVerify) {
            // 인증번호 필드로 포커스 이동
            this.moveFocus("emailCertificationNumber");
          } else {
            this.emailAuth.verified = true;
          }
        }, 200);
      }
    },
    "form.phoneCertificationNumber"(val) {
      if (this.isNotBlank(val) && val.length === 6) {
        // 휴대전화번호 인증번호 검증
        console.log("휴대전화번호 인증번호 검증");
        this.processing = true;
        this.resetValidate();
        setTimeout(async () => {
          const phoneVerify = await this.checkPhoneVerify();
          // console.log("emailVerify : ", emailVerify);
          this.processing = false;
          if (!phoneVerify) {
            // 인증번호 필드로 포커스 이동
            this.moveFocus("phoneCertificationNumber");
          } else {
            this.phoneAuth.verified = true;
          }
        }, 200);
      }
    },
    type(type) {
      if (type === "view") {
        const user = this.user as UserModel;
        this.view.username = user.username;
        this.form.email = user.email;
        this.form.name = user.name;
      }
    },
    "modal.selectCamera.updateEvent"(event) {
      if (event != null) {
        if (event.result === UpdateEventType.CONFIRM) {
          const item = event.item;
          if (item === "camera") {
            (this.$refs.profileCameraFile as any).click();
          } else if (item === "image") {
            (this.$refs.profileImageFile as any).click();
          } else {
            console.log("unknown item : ", item);
          }
        }
      }
    },
  },
  methods: {
    async sendPhoneVerify() {
      //console.log("call send phone verify");
      await this.validate();
      const observer = this.$refs.observer as any;
      if (
        observer.errors != null &&
        observer.errors.user_phone &&
        observer.errors.user_phone.length > 0
      ) {
        return;
      } else if (this.isBlank(this.form.phone)) {
        return;
      }
      const auth = this.phoneAuth;
      auth.processing = true;

      try {
        const token = (await UserService.createPhoneVerify(this.form.phone)) as string;
        this.form.phoneVerifyToken = token;
        this.form.phoneCertificationNumber = "";
        //console.log("token : ", token);
        auth.visible.help = true;
        auth.date = new Date();
        auth.expired = false;
        auth.remainingTimeMin = "10분";
        if (auth.interval == null) {
          auth.interval = setInterval(() => {
            const date = new Date();
            if (auth.date != null) {
              const elapsedTimeMillis = date.getTime() - auth.date.getTime();
              const remainingTimeMillis = auth.waitTimeMillis - elapsedTimeMillis;
              if (remainingTimeMillis > 0) {
                auth.remainingTimeMin =
                  String(parseInt(String(remainingTimeMillis / 60000)) + 1) + "분";
                //console.log("remainingTimeMillis : ", remainingTimeMillis);
              } else {
                auth.date = null;
                auth.remainingTimeMin = "만료";
                auth.expired = true;
                auth.verified = false;
                this.form.phoneVerifyToken = "";
                this.form.phoneCertificationNumber = "";
              }
            } else if (auth.interval != null) {
              clearInterval(auth.interval);
              auth.interval = null;
            }
          }, 200);

          // 인증번호 필드로 포커스 이동
          this.moveFocus("phoneCertificationNumber");
          this.resetValidate();
        }
      } catch (e) {
        console.log(e);
        this.errorSubmit(e, this.formConvertField);
      } finally {
        auth.processing = false;
      }
    },
    async checkPhoneVerify() {
      const token = this.form.phoneVerifyToken;
      const phoneCertificationNumber = this.form.phoneCertificationNumber;
      const phone = this.form.phone;
      try {
        const result = (await UserService.checkPhoneVerify(
          token,
          phone,
          phoneCertificationNumber
        )) as string;
        //console.log("result : ", result);
        return true;
      } catch (e) {
        //console.log("error : ", e);
        this.errorSubmit(e, this.formConvertField);
        return false;
      }
    },

    async sendEmailVerify() {
      //console.log("call send email verify");
      await this.validate();
      const observer = this.$refs.observer as any;
      if (
        observer.errors != null &&
        observer.errors.user_email &&
        observer.errors.user_email.length > 0
      ) {
        return;
      } else if (this.isBlank(this.form.email)) {
        return;
      }
      const auth = this.emailAuth;
      auth.processing = true;

      try {
        const token = (await UserService.createEmailVerify(this.form.email)) as string;
        this.form.emailVerifyToken = token;
        this.form.emailCertificationNumber = "";
        //console.log("token : ", token);
        auth.visible.help = true;
        auth.date = new Date();
        auth.expired = false;
        auth.remainingTimeMin = "10분";
        if (auth.interval == null) {
          auth.interval = setInterval(() => {
            const date = new Date();
            if (auth.date != null) {
              const elapsedTimeMillis = date.getTime() - auth.date.getTime();
              const remainingTimeMillis = auth.waitTimeMillis - elapsedTimeMillis;
              if (remainingTimeMillis > 0) {
                auth.remainingTimeMin =
                  String(parseInt(String(remainingTimeMillis / 60000)) + 1) + "분";
                //console.log("remainingTimeMillis : ", remainingTimeMillis);
              } else {
                auth.date = null;
                auth.remainingTimeMin = "만료";
                auth.expired = true;
                auth.verified = false;
                this.form.emailVerifyToken = "";
                this.form.emailCertificationNumber = "";
              }
            } else if (auth.interval != null) {
              clearInterval(auth.interval);
              auth.interval = null;
            }
          }, 200);

          // 인증번호 필드로 포커스 이동
          this.moveFocus("emailCertificationNumber");
          this.resetValidate();
        }
      } catch (e) {
        console.log(e);
        this.errorSubmit(e, this.formConvertField);
      } finally {
        auth.processing = false;
      }
    },
    async checkEmailVerify() {
      const token = this.form.emailVerifyToken;
      const emailCertificationNumber = this.form.emailCertificationNumber;
      const email = this.form.email;
      try {
        const result = (await UserService.checkEmailVerify(
          token,
          email,
          emailCertificationNumber
        )) as string;
        //console.log("result : ", result);
        return true;
      } catch (e) {
        //console.log("error : ", e);
        this.errorSubmit(e, this.formConvertField);
        return false;
      }
    },
    async submit() {
      if (this.phoneAuth.visible.btn) {
        if (this.isBlank(this.form.phoneVerifyToken)) {
          await this.sendPhoneVerify();
          return;
        }
      }
      if (this.emailAuth.visible.btn) {
        if (this.isBlank(this.form.emailVerifyToken)) {
          await this.sendEmailVerify();
          return;
        }
      }

      if (await this.validate()) {
        this.processing = true;
        try {
          const params = cloneDeep(this.form) as any;
          if (this.isBlank(params.phone)) {
            delete params.phone;
          }
          if (this.isBlank(params.email)) {
            delete params.email;
          }

          await UserService.update(params);
          const user: UserModel = await this.$store.getters["auth/user"](true);
          core.loader.hide();

          await core.alert.show({
            title: "알림",
            body: "회원정보가 수정되었습니다.",
          });
          await this.$router.back();
        } catch (e) {
          core.loader.hide();
          this.errorSubmit(e, this.formConvertField);
        }
        this.processing = false;
      }
    },
  },
});
