import AbstractTableService from "@/services/core/abstract-table.service";
import { ProfileModel } from "@/models/profile/profile.model";

class ProfileService extends AbstractTableService<ProfileModel> {
  constructor() {
    super("/api/v1/profile", "profile");
  }
}

export default new ProfileService();
