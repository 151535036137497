import AbstractTableService from "@/services/core/abstract-table.service";
import { ProfileModel } from "@/models/profile/profile.model";

class FavoriteService extends AbstractTableService<ProfileModel> {
  constructor() {
    super("/api/v1/favorite", "favorite");
  }
}

export default new FavoriteService();
