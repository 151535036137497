
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import store from "@/store";
import HomeComponent from "@/components/home/HomeComponent.vue";
import core from "@/core";
import { LayoutType, RouteMeta } from "@/router";
import { cloneDeep } from "lodash";
import MixinsAppBarMenu from "@/mixins/single/app-bar-menu";

export default mixins(MixinsPageForm, MixinsAppBarMenu).extend({
  name: "Home",
  components: { HomeComponent },
  data: () => ({
    extensionHeight: 65,
    bodyMaxHeight: 0,
    app: store.state.app,
    prop: null as any,
  }),
  created() {
    this.windowResize();
  },
  mounted() {
    this.$nextTick(() => {
      const meta = this.$route.meta as RouteMeta;
      const layoutList = cloneDeep(meta.layoutList) as LayoutType[];
      layoutList.push(LayoutType.APP_BAR);
      this.prop = {
        layoutList: layoutList,
        appBarMenu: meta.appBarMenu,
      };

      this.windowResize();
    });
  },
  computed: {
    appBarImage() {
      const max = 2,
        min = 1;
      const index = parseInt(String(Math.random() * (max + 1 - min) + min));
      return `/img/home/home-${index}.jpg`;
    },
  },
  watch: {
    "app.size"() {
      this.windowResize();
    },
    "app.webSocketConnected"() {
      this.windowResize();
    },
  },
  methods: {
    windowResize() {
      this.bodyMaxHeight = this.app.size.height - 56;
    },
    moveProfileSearchPage() {
      this.$router.push({
        path: "/profile/search",
        query: { stackKey: core.utils.textGenerator(8) },
      });
    },
  },
});
