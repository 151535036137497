import AbstractTableService from "@/services/core/abstract-table.service";
import { NotificationModel } from "@/models/user/notification.model";

class Notification extends AbstractTableService<NotificationModel> {
  constructor() {
    super("/api/v1/notification", "notification");
  }
}

export default new Notification();
