
import mixins from "vue-typed-mixins";
import MixinsCommon from "@/mixins/single/common";
import { ProfileModel } from "@/models/profile/profile.model";
import core from "@/core";

export default mixins(MixinsCommon).extend({
  name: "ProfileListItemComponent",
  props: {
    item: {
      type: Object as () => ProfileModel,
      default: () => {
        return null as ProfileModel | null;
      },
    },
    date: {
      type: Number,
      default: () => {
        return 0;
      },
    },
    visibleFavorite: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    convertDate(numDate: number) {
      const date = new Date(numDate);
      const momentDate = core.date.instance(date);
      return momentDate.format("YYYY-MM-DD HH:mm:ss");
    },
  },
});
