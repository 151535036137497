
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import { UserModel } from "@/models/user/user.model";
import { AppBarMenuItem } from "@/types";
import MixinsAppBarMenu from "@/mixins/single/app-bar-menu";

export default mixins(MixinsPageForm, MixinsAppBarMenu).extend({
  name: "UserMyInfoView",
  data: () => ({
    type: "view",
    view: {
      username: "",
      email: "",
      name: "",
      phone: "",
    },
    user: null as UserModel | null,
  }),
  mounted() {
    // 페이지 로딩 후 호출
    this.$nextTick(async () => {
      const user: UserModel = (this.user = await this.$store.getters["auth/user"]());
      //console.log("user : ", user);
      this.view.username = user.username;
      this.view.email = user.email;
      this.view.name = user.name;
      this.view.phone = this.phoneFormat(user.phone);
    });
  },
  methods: {
    appBarMenuEvent(menu: AppBarMenuItem) {
      if (menu.id === "edit") {
        this.$router.push("/user/me/info/edit");
      } else {
        console.log("unknown id : ", menu.id);
      }
    },
  },
});
