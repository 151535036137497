
import mixins from "vue-typed-mixins";
import MixinsModalForm from "@/mixins/modal-form";
import { UpdateEventType } from "@/types";

export default mixins(MixinsModalForm).extend({
  name: "NotificationFilterModal",
  data: () => ({
    selectAll: false,
    selectedFilterList: [] as any,
    filterList: [
      {
        id: "ENTITY_TYPE_IS_NULL",
        name: "일반",
      },
      {
        id: "USER",
        name: "사용자",
      },
    ],
  }),
  mounted() {
    // 페이지 로딩 후 호출
    this.$nextTick(async () => {
      const filterList = this.properties.params.filterList;
      if (filterList != null) {
        this.filterList.forEach((filter) => {
          filterList.some((_filter) => {
            if (_filter.id === filter.id) {
              this.selectedFilterList.push(filter);
              return true;
            }
          });
        });
      }
      // console.log("filterList : ", filterList);
    });
  },
  watch: {
    selectAll(selectAll) {
      if (selectAll && this.selectedFilterList.length != this.filterList.length) {
        const selectedFilterList = [] as any;
        this.filterList.forEach((item) => {
          selectedFilterList.push(item);
        });
        this.selectedFilterList = selectedFilterList;
      } else if (!selectAll && this.selectedFilterList.length > 0) {
        this.selectedFilterList = [];
      }
    },
    selectedFilterList(val) {
      if (val != null) {
        //console.log("val : ", val);
        if (this.selectAll && val.length === 0) {
          this.selectAll = false;
        } else if (!this.selectAll && val.length === this.filterList.length) {
          this.selectAll = true;
        }
      }
    },
  },
  methods: {
    confirm() {
      if (this.selectedFilterList.length > 0) {
        this.close(UpdateEventType.CONFIRM, this.selectedFilterList);
      } else {
        this.close(UpdateEventType.CONFIRM, null);
      }
    },
  },
});
