
import core from "@/core";
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import { UserModel } from "@/models/user/user.model";
import UserService from "@/services/user/user.service";
import { cloneDeep } from "lodash";
import UpdateEvent from "@/models";
import { UpdateEventType } from "@/types";
import TextFieldModal from "@/modals/core/TextFieldModal.vue";
import store from "@/store";
import Constant from "../../store/constant";
import { UserProfileModel } from "@/models/user/user-profile.model";
import CategoryService from "@/services/category/category.service";

import draggable from "vuedraggable";
import { CategoryModel } from "@/models/category/category.model";
import ImageCropperModal from "@/modals/core/ImageCropperModal.vue";
import SelectPhotoModal from "@/modals/core/SelectPhotoModal.vue";

export default mixins(MixinsPageForm).extend({
  name: "UserMyProfile",
  components: {
    SelectPhotoModal,
    ImageCropperModal,
    TextFieldModal,
    draggable,
  },
  data: () => ({
    ready: false,
    type: "add",
    checkbox: {
      phonePublic: false,
      officeNumberPublic: false,
      addressPublic: false,
    },
    form: {
      jgCategoryId: null as null | number,
      koName: "",
      enName: "",
      organization: "",
      position: "",
      phone: "",
      phonePublicYn: "",
      officeNumber: "",
      officeNumberPublicYn: "",
      email: "",
      address: "",
      addressPublicYn: "",
      educationList: [] as string[],
      majorCareerList: [] as string[],
      categoryList: [] as CategoryModel[],
      fileDetailId: null as any,
    },
    accept: "image/*",
    image: {
      item: null,
      src: null,
    } as any,
    user: null as UserModel | null,
    profile: null as UserProfileModel | null,
    processing: false,
    // iconSubMenu: { edit: null as Menu | null },
    jgCategoryList: [] as any,
    pfCategoryList: [] as any,
    modal: {
      imageCropper: {
        visible: false,
        params: {
          filename: "",
          dataUri: "",
        },
        updateEvent: null as UpdateEvent | null,
      },
      category: {
        selected: null as any,
        visible: false,
      },
      selectPhoto: {
        visible: false,
        visibleDefaultImage: false,
        updateEvent: null as UpdateEvent | null,
      },
      education: {
        visible: false,
        params: {
          length: {
            min: 1,
            max: 100,
          },
          type: "add",
          title: "학력",
          enableDeleteButton: false,
          text: "",
          item: null as any,
          confirmButtonText: "추가",
        },
        updateEvent: null as UpdateEvent | null,
      },
      majorCareer: {
        visible: false,
        params: {
          length: {
            min: 1,
            max: 100,
          },
          type: "add",
          title: "주요경력",
          placeholder: "예 (00-01) A센터 B실장",
          enableDeleteButton: false,
          text: "",
          item: null as any,
          confirmButtonText: "추가",
        },
        updateEvent: null as UpdateEvent | null,
      },
    },

    formConvertField: {
      koName: "profile_koName",
      enName: "profile_enName",
      organization: "profile_organization",
      position: "profile_position",
      officeNumber: "profile_officeNumber",
      phone: "profile_phone",
      email: "profile_email",
      address: "profile_address",
    },
  }),
  mounted() {
    // 페이지 로딩 후 호출
    this.$nextTick(async () => {
      this.jgCategoryList = await CategoryService.getJgList();
      this.pfCategoryList = await CategoryService.getPfList();
      // console.log("jgCategoryList : ", jgCategoryList);

      const user: UserModel = (this.user = await this.$store.getters["auth/user"]());
      // 사용자 프로필 조회
      const profile: UserProfileModel | null = await store.getters["auth/profile"]();
      if (profile == null) {
        this.type = "add";
        this.clearImage();
        this.form.email = user.email;
      } else {
        this.type = "edit";
        if (profile.image != null) {
          this.image.src = profile.image.uri;
          this.image.item = null;
          this.form.fileDetailId = profile.image.id;
        }
        for (const key of Object.keys(profile)) {
          if (this.form[key] !== undefined) {
            // console.log("key : ", key);
            this.form[key] = profile[key];
          } else {
            // console.log("unknown key : ", key, this.form[key]);
          }
        }
        if (profile.jgCategory != null) {
          this.form.jgCategoryId = profile.jgCategory.id;
        }
        // console.log("this.form.jgCategoryId : ", this.form.jgCategoryId);
        this.checkbox.phonePublic = profile.phonePublicYn === "Y";
        this.checkbox.officeNumberPublic = profile.officeNumberPublicYn === "Y";
        this.checkbox.addressPublic = profile.addressPublicYn === "Y";
      }
      this.ready = true;
    });
  },
  watch: {
    "modal.category.selected"(addCategory) {
      if (addCategory != null) {
        console.log("add category : ", addCategory);
        let exists = false;
        this.form.categoryList.some((category) => {
          if (category.id === addCategory.id) {
            exists = true;
            return true;
          }
        });
        if (!exists) {
          this.form.categoryList.push(addCategory);
        }
      }
    },
    "modal.education.updateEvent"() {
      const event = this.getComponentUpdateEvent(this.modal.education);
      if (event != null) {
        if (event.result == UpdateEventType.CONFIRM) {
          const item = event.item;
          this.form.educationList.push(item);
        }
      }
    },
    "modal.majorCareer.updateEvent"() {
      const event = this.getComponentUpdateEvent(this.modal.majorCareer);
      if (event != null) {
        if (event.result == UpdateEventType.CONFIRM) {
          const item = event.item;
          this.form.majorCareerList.push(item);
        }
      }
    },
    "modal.category.updateEvent"() {
      const event = this.getComponentUpdateEvent(this.modal.category);
      if (event != null) {
        if (event.result == UpdateEventType.CONFIRM) {
          const item = event.item;
          this.form.categoryList.push(item);
        }
      }
    },
    "modal.selectPhoto.updateEvent"(event) {
      if (event != null) {
        if (event.result === UpdateEventType.CONFIRM) {
          const item = event.item;
          if (item === "camera") {
            (this.$refs.profileCameraFile as any).click();
          } else if (item === "image") {
            (this.$refs.profileImageFile as any).click();
          } else if (item === "defaultImage") {
            this.clearImage();
          } else {
            console.log("unknown item : ", item);
          }
        }
      }
    },
    "modal.imageCropper.updateEvent"() {
      const modal = this.modal.imageCropper;
      const event = this.getComponentUpdateEvent(modal);
      if (event != null) {
        if (event.result === UpdateEventType.CONFIRM) {
          const result = event.item;
          if (result != null) {
            this.image.item = result;
            this.image.src = result.dataUri;
          }
        }
      }
    },
  },
  methods: {
    Constant() {
      return Constant;
    },
    addCategory() {
      const modal = this.modal.category;
      modal.visible = true;
    },
    removeCategory(position: number) {
      const list = this.form.categoryList;
      list.splice(position, 1);
    },
    addMajorCareer() {
      const modal = this.modal.majorCareer;
      modal.visible = true;
    },
    removeCareer(position: number) {
      const list = this.form.majorCareerList;
      list.splice(position, 1);
    },
    addEducation() {
      const modal = this.modal.education;
      modal.visible = true;
    },
    removeEducation(position: number) {
      const list = this.form.educationList;
      list.splice(position, 1);
    },
    showPhotoModal() {
      const modal = this.modal.selectPhoto;
      modal.visibleDefaultImage = this.image.src !== "/img/user/profile.png";
      modal.visible = true;
    },
    onChangeImageFile(e) {
      this.inputImageFile(e.target.files);
    },
    inputImageFile(files) {
      if (files.length) {
        let file = files[0];
        if (!/^image\//.test(file.type)) {
          core.alert.show({
            title: "알림",
            body: "이미지 파일만 등록이 가능합니다",
          });
          return false;
        }
        this.image.name = file.name;
        this.showImageCropperModal(file);
        (this.$refs.profileImageFile as any).value = "";
      }
    },

    clearImage() {
      this.image.src = "/img/user/profile.png";
      this.image.item = null;
      this.form.fileDetailId = null;
    },
    async showImageCropperModal(file) {
      core.loader.show("이미지 변환중...");
      const item = (await core.utils.image.getImageBlob(file)) as any;
      this.modal.imageCropper.params.filename = item.filename;
      this.modal.imageCropper.params.dataUri = item.dataUri;
      this.modal.imageCropper.visible = true;
      core.loader.hide();
    },
    async submit() {
      if (await this.validate()) {
        this.processing = true;

        try {
          const params = cloneDeep(this.form) as any;
          const categoryList: CategoryModel[] = params.categoryList;
          delete params.categoryList;
          params.categoryList = [] as number[];
          categoryList.forEach((category) => {
            params.categoryList.push(category.id);
          });
          if (this.isBlank(params.phone)) {
            delete params.phone;
            params.phonePublicYn = "N";
          } else {
            params.phonePublicYn = this.checkbox.phonePublic ? "Y" : "N";
          }

          if (this.isBlank(params.officeNumber)) {
            delete params.officeNumber;
            params.officeNumberPublicYn = "N";
          } else {
            params.officeNumberPublicYn = this.checkbox.officeNumberPublic ? "Y" : "N";
          }

          if (this.isBlank(params.address)) {
            delete params.address;
            params.addressPublicYn = "N";
          } else {
            params.addressPublicYn = this.checkbox.addressPublic ? "Y" : "N";
          }

          if (this.image.item != null) {
            const item = this.image.item;
            //console.log("item : ", item);
            // image 저장
            const formData = new FormData();
            formData.append("imageFile", item.blob, item.filename);
            const fileDetailId = await UserService.createProfileImage(formData);
            params.fileDetailId = fileDetailId;
          }

          if (this.type === "add") {
            const profile = await UserService.createProfile(params);
            // console.log("create profile : ", profile);
            await core.alert.show({
              title: "알림",
              body: "프로필 정보가 저장되었습니다",
            });
            await this.$router.push("/");
          } else if (this.type === "edit") {
            const profile = await UserService.updateProfile(params);
            // console.log("update profile : ", profile);
            await core.alert.show({
              title: "알림",
              body: "프로필 정보가 저장되었습니다",
            });
            await this.$router.back();
          }
        } catch (e) {
          core.loader.hide();
          this.errorSubmit(e, this.formConvertField);
        } finally {
          this.processing = false;
        }
      }
    },
  },
});
