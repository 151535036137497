
import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";
import core from "@/core";
import ProfileService from "@/services/profile/profile.service";
import { ProfileModel } from "@/models/profile/profile.model";
import { AppBarMenuItem, ErrorResponse } from "@/types";
import MixinsAppBarMenu from "@/mixins/single/app-bar-menu";

export default mixins(MixinsPage, MixinsAppBarMenu).extend({
  name: "UserProfileView",
  components: {},
  data: () => ({
    dateFormatPattern: "YYYY년 MM월 DD일",
    profile: null as ProfileModel | null,
  }),
  computed: {
    isAdminRole() {
      return this.$store.getters["auth/isAdminRole"];
    },
    isManagerRoleHigher() {
      return this.$store.getters["auth/isManagerRoleHigher"];
    },
  },
  mounted() {
    this.$nextTick(async () => {
      const params = this.$route.params;
      //console.log("query : ", query);
      if (this.isBlank(params.id)) {
        await this.notFound();
        return;
      }
      const id = Number(params.id);
      await this.loadData(id);
      this.appBarChangeMenuVisible("edit", this.isManagerRoleHigher);
    });
  },
  methods: {
    appBarMenuEvent(menu: AppBarMenuItem) {
      if (menu.id === "edit") {
        if (this.profile != null) {
          this.$router.push(`/mgmt/user/${this.profile.id}/profile/edit`);
        }
      } else {
        console.log("unknown id : ", menu.id);
      }
    },
    async loadData(id: number) {
      try {
        const profile = (this.profile = await ProfileService.get(id)) as ProfileModel;
      } catch (error) {
        const e = error as ErrorResponse;
        console.log(e);
        await this.notFound();
        return;
      }
    },
    getMailLink(email) {
      return core.mobile.getMailLink(email);
    },
    getTelLink(phone) {
      return core.mobile.getTelLink(phone);
    },
  },
});
