
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import store from "@/store";
import MixinsStorage from "@/mixins/single/storage";
import ProfileListItemComponent from "@/components/profile/ListItemComponent.vue";
import { StorageProfileView } from "@/types";
import core from "@/core";
import DashboardService from "@/services/dashboard/dashboard.service";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5 from "@amcharts/amcharts5";
import * as am5radar from "@amcharts/amcharts5/radar";
import * as am5xy from "@amcharts/amcharts5/xy";

export default mixins(MixinsPageForm, MixinsStorage).extend({
  name: "HomeComponent",
  components: { ProfileListItemComponent },
  data: () => ({
    storageItems: {
      type: "profile-view",
      items: {},
    },
    visible: {
      chart: false,
    },
    app: store.state.app,
    profileViewList: [] as StorageProfileView[],
  }),
  mounted() {
    this.$nextTick(async () => {
      const user = await store.getters["auth/user"]();
      // 조회한 프로필 저장
      await this.storageWait();
      const items = this.getStorageItems();
      // console.log("items : ", items);
      if (items.list == null) {
        items.list = [];
      }
      const list = items.list as StorageProfileView[];
      // for (let i = 0; i < 5; i++) {
      //   this.profileViewList.push(list[i]);
      // }
      this.profileViewList = list;

      DashboardService.getJgCategoryCountList().then((jgCategoryCountList) => {
        // console.log("jgCategoryCountList : ", jgCategoryCountList);
        this.createChart(jgCategoryCountList);
      });
      // console.log("list : ", list);
    });
  },
  methods: {
    createChart(jgCategoryCountList) {
      if (this.$refs.chart == undefined) return;
      /* Chart code */
      // Create root element
      // https://www.amcharts.com/docs/v5/getting-started/#Root_element
      let root = am5.Root.new(this.$refs.chart as any);

      // Set themes
      // https://www.amcharts.com/docs/v5/concepts/themes/
      root.setThemes([am5themes_Animated.new(root)]);

      // Create chart
      // https://www.amcharts.com/docs/v5/charts/radar-chart/
      let chart = root.container.children.push(
        am5radar.RadarChart.new(root, {
          panX: false,
          panY: false,
          wheelX: "panX",
          wheelY: "zoomX",
          innerRadius: am5.percent(20),
          startAngle: -90,
          endAngle: 180,
        })
      ) as any;

      // Data
      const data = [] as any;
      let totalCount = 0;
      jgCategoryCountList.forEach((category) => {
        totalCount += category.count;
      });
      if (totalCount > 0) {
        this.visible.chart = true;
      }
      //console.log("totalCount : ", totalCount);
      jgCategoryCountList.forEach((category, index) => {
        const per = category.count === 0 ? 0 : ((category.count / totalCount) * 100).toFixed(1);
        //console.log("per : ", per);
        data.push({
          category: category.name,
          value: per,
          full: 100,
          columnSettings: {
            fill: chart.get("colors").getIndex(index),
          },
        });
      });

      // Add cursor
      // https://www.amcharts.com/docs/v5/charts/radar-chart/#Cursor
      let cursor = chart.set(
        "cursor",
        am5radar.RadarCursor.new(root, {
          behavior: "zoomX",
        })
      );

      cursor.lineY.set("visible", false);

      // Create axes and their renderers
      // https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_axes
      let xRenderer = am5radar.AxisRendererCircular.new(root, {
        //minGridDistance: 50
      });

      xRenderer.labels.template.setAll({
        radius: 10,
      });

      xRenderer.grid.template.setAll({
        forceHidden: true,
      });

      let xAxis = chart.xAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: xRenderer,
          min: 0,
          max: 100,
          strictMinMax: true,
          numberFormat: "#'%'",
          tooltip: am5.Tooltip.new(root, {}),
        })
      );

      let yRenderer = am5radar.AxisRendererRadial.new(root, {
        minGridDistance: 20,
      });

      yRenderer.labels.template.setAll({
        centerX: am5.p100,
        fontWeight: "500",
        fontSize: 18,
        templateField: "columnSettings",
      });

      yRenderer.grid.template.setAll({
        forceHidden: true,
      });

      let yAxis = chart.yAxes.push(
        am5xy.CategoryAxis.new(root, {
          categoryField: "category",
          renderer: yRenderer,
        })
      );

      yAxis.data.setAll(data);

      // Create series
      // https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_series
      let series1 = chart.series.push(
        am5radar.RadarColumnSeries.new(root, {
          xAxis: xAxis,
          yAxis: yAxis,
          clustered: false,
          valueXField: "full",
          categoryYField: "category",
          fill: root.interfaceColors.get("alternativeBackground"),
        })
      );

      series1.columns.template.setAll({
        width: am5.p100,
        fillOpacity: 0.08,
        strokeOpacity: 0,
        cornerRadius: 20,
      });

      series1.data.setAll(data);

      let series2 = chart.series.push(
        am5radar.RadarColumnSeries.new(root, {
          xAxis: xAxis,
          yAxis: yAxis,
          clustered: false,
          valueXField: "value",
          categoryYField: "category",
        })
      );

      series2.columns.template.setAll({
        width: am5.p100,
        strokeOpacity: 0,
        tooltipText: "{category}: {valueX}%",
        cornerRadius: 20,
        templateField: "columnSettings",
      });

      series2.data.setAll(data);

      // Animate chart and series in
      // https://www.amcharts.com/docs/v5/concepts/animations/#Initial_animation
      series1.appear(1000);
      series2.appear(1000);
      chart.appear(1000, 100);
    },
  },
});
