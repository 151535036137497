import AbstractTableService from "@/services/core/abstract-table.service";
import { CategoryModel } from "@/models/category/category.model";
import core from "@/core";

class CategoryService extends AbstractTableService<CategoryModel> {
  constructor() {
    super("/api/v1/category", "category");
  }

  getJgList(): Promise<CategoryModel[]> {
    const url = this.getUrl() + "/jg";
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`${url}`, null, null)
        .then((data) => {
          if (typeof data === "object") {
            const item = data[this.itemKey + "List"];
            if (item != null) {
              resolve(item);
            } else {
              reject({
                message: "not found item",
              });
            }
          } else {
            reject({
              message: "data type error",
            });
          }
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  getPfList(): Promise<CategoryModel[]> {
    const url = this.getUrl() + "/pf";
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`${url}`, null, null)
        .then((data) => {
          if (typeof data === "object") {
            const item = data[this.itemKey + "List"];
            if (item != null) {
              resolve(item);
            } else {
              reject({
                message: "not found item",
              });
            }
          } else {
            reject({
              message: "data type error",
            });
          }
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }
}

export default new CategoryService();
