import { CategoryModel } from "@/models/category/category.model";
import core from "@/core";
import AbstractCoreService from "@/services/core/abstract-core.service";

class CategoryService extends AbstractCoreService<any> {
  constructor() {
    super("/api/v1/dashboard", "");
  }

  getJgCategoryCountList(): Promise<CategoryModel[]> {
    const url = this.getUrl() + "/jg-category-count";
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`${url}`, null, null)
        .then((data) => {
          if (typeof data === "object") {
            const item = data["jgCategoryCountList"];
            if (item != null) {
              resolve(item);
            } else {
              reject({
                message: "not found item",
              });
            }
          } else {
            reject({
              message: "data type error",
            });
          }
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }
}

export default new CategoryService();
