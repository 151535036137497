
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import UserService from "@/services/user/user.service";

export default mixins(MixinsPageForm).extend({
  name: "FindUsername",
  data: () => ({
    processing: false,
    authType: "phone",
    authTypeList: [
      { id: "phone", text: "휴대전화" },
      { id: "email", text: "이메일" },
    ],
    form: {
      email: "",
      phone: "",
      phoneCertificationNumber: "",
      phoneVerifyToken: "",
    },
    emailAuth: {
      visible: {
        help: false,
      },
      waitTimeMillis: 600000,
      remainingTimeMin: "10",
      date: null as Date | null,
      interval: null as number | null,
      expired: false,
      processing: false,
    },
    phoneAuth: {
      visible: {
        help: false,
      },
      verified: false,
      waitTimeMillis: 600000,
      remainingTimeMin: "10",
      date: null as Date | null,
      interval: null as number | null,
      expired: false,
      processing: false,
    },
    formConvertField: {
      email: "user_email",
      phone: "user_phone",
      phoneCertificationNumber: "user_phoneCertificationNumber",
    },
    find: {
      result: false,
      password: "",
    },
  }),
  beforeRouteLeave(to, from, next) {
    [this.emailAuth].forEach((auth) => {
      if (auth.interval != null) {
        clearInterval(auth.interval);
        auth.interval = null;
      }
    });
    next();
  },
  mounted() {
    this.$nextTick(async () => {
      const token = this.$route.query.t;
      if (this.isNotBlank(token)) {
        this.replaceState();

        // token 검증
        try {
          this.processing = true;
          const result = (await UserService.getFindPassword(token as string)) as any;
          this.authType = "email";
          this.form.email = result.email;
          this.find.result = true;
          this.find.password = result.password;
          console.log("result : ", result);
        } catch (e) {
          console.log(e);
        } finally {
          this.processing = false;
        }
      }
    });
  },
  watch: {
    "form.phone"() {
      this.phoneAuth.verified = false;
      this.form.phone = this.phoneFormat(this.form.phone);
      this.form.phoneVerifyToken = "";
      this.form.phoneCertificationNumber = "";
      this.phoneAuth.visible.help = false;
    },
    "form.email"() {
      this.emailAuth.visible.help = false;
    },
    "form.phoneCertificationNumber"(val) {
      if (this.isNotBlank(val) && val.length === 6) {
        // 휴대전화 인증번호 검증
        console.log("휴대전화 인증번호 검증");
        this.processing = true;
        this.resetValidate();
        setTimeout(async () => {
          const phoneVerify = await this.checkPhoneVerify();
          // console.log("phoneVerify : ", phoneVerify);
          this.processing = false;
          if (!phoneVerify) {
            // 인증번호 필드로 포커스 이동
            this.moveFocus("phoneCertificationNumber");
          } else {
            this.phoneAuth.verified = true;
            this.submit();
          }
        }, 200);
      }
    },
  },
  methods: {
    async sendPhoneVerify() {
      //console.log("call send phone verify");
      await this.validate();
      const observer = this.$refs.observer as any;
      if (
        observer.errors != null &&
        observer.errors.user_phone &&
        observer.errors.user_phone.length > 0
      ) {
        return;
      } else if (this.isBlank(this.form.phone)) {
        return;
      }
      const auth = this.phoneAuth;
      auth.processing = true;

      try {
        const token = (await UserService.sendPhoneFindPassword(this.form.phone)) as string;
        this.form.phoneVerifyToken = token;
        this.form.phoneCertificationNumber = "";
        //console.log("token : ", token);
        auth.visible.help = true;
        auth.date = new Date();
        auth.expired = false;
        auth.remainingTimeMin = "10분";
        if (auth.interval == null) {
          auth.interval = setInterval(() => {
            const date = new Date();
            if (auth.date != null) {
              const elapsedTimeMillis = date.getTime() - auth.date.getTime();
              const remainingTimeMillis = auth.waitTimeMillis - elapsedTimeMillis;
              if (remainingTimeMillis > 0) {
                auth.remainingTimeMin =
                  String(parseInt(String(remainingTimeMillis / 60000)) + 1) + "분";
                //console.log("remainingTimeMillis : ", remainingTimeMillis);
              } else {
                auth.date = null;
                auth.remainingTimeMin = "만료";
                auth.expired = true;
                auth.verified = false;
                this.form.phoneVerifyToken = "";
                this.form.phoneCertificationNumber = "";
              }
            } else if (auth.interval != null) {
              clearInterval(auth.interval);
              auth.interval = null;
            }
          }, 200);

          // 인증번호 필드로 포커스 이동
          this.moveFocus("phoneCertificationNumber");
          this.resetValidate();
        }
      } catch (e) {
        console.log(e);
        this.errorSubmit(e, this.formConvertField);
      } finally {
        auth.processing = false;
      }
    },
    async checkPhoneVerify() {
      const token = this.form.phoneVerifyToken;
      const phoneCertificationNumber = this.form.phoneCertificationNumber;
      const phone = this.form.phone;
      try {
        const result = (await UserService.checkPhoneVerify(
          token,
          phone,
          phoneCertificationNumber
        )) as string;
        console.log("result : ", result);
        return true;
      } catch (e) {
        //console.log("error : ", e);
        this.errorSubmit(e, this.formConvertField);
        return false;
      }
    },
    async submit() {
      if (await this.validate()) {
        if (this.authType === "email") {
          await this.submitEmail();
        } else if (this.authType === "phone") {
          await this.submitPhone();
        } else {
          console.log("invalid auth type. ", this.authType);
        }
      }
    },
    async submitPhone() {
      console.log("submit phone");
      if (this.isBlank(this.form.phoneVerifyToken)) {
        await this.sendPhoneVerify();
        return;
      }
      const result = (await UserService.getFindPassword(
        this.form.phoneVerifyToken,
        this.form.phone,
        this.form.phoneCertificationNumber
      )) as any;
      this.find.result = true;
      this.find.password = result.password;
    },
    async submitEmail() {
      const auth = this.emailAuth;
      auth.processing = true;

      try {
        const res = (await UserService.sendEmailFindPassword(this.form.email)) as string;
        //console.log("token : ", token);
        auth.visible.help = true;
        auth.date = new Date();
        auth.expired = false;
        auth.remainingTimeMin = "10분";
        if (auth.interval == null) {
          auth.interval = setInterval(() => {
            const date = new Date();
            if (auth.date != null) {
              const elapsedTimeMillis = date.getTime() - auth.date.getTime();
              const remainingTimeMillis = auth.waitTimeMillis - elapsedTimeMillis;
              if (remainingTimeMillis > 0) {
                auth.remainingTimeMin =
                  String(parseInt(String(remainingTimeMillis / 60000)) + 1) + "분";
                //console.log("remainingTimeMillis : ", remainingTimeMillis);
              } else {
                auth.date = null;
                auth.remainingTimeMin = "만료";
                auth.expired = true;
              }
            } else if (auth.interval != null) {
              clearInterval(auth.interval);
              auth.interval = null;
            }
          }, 200);

          // 인증번호 필드로 포커스 이동
          this.moveFocus("emailCertificationNumber");
          this.resetValidate();
        }
      } catch (e) {
        console.log(e);
        this.errorSubmit(e, this.formConvertField);
      } finally {
        auth.processing = false;
      }
    },
  },
});
