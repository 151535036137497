
import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";
import core from "@/core";
import ProfileService from "@/services/profile/profile.service";
import { ProfileModel } from "@/models/profile/profile.model";
import MixinsStorage from "@/mixins/single/storage";
import { AppBarMenuItem, ErrorResponse, StorageProfileView, UpdateEventType } from "@/types";
import FavoriteService from "@/services/favorite/favorite.service";
import MixinsAppBarMenu from "@/mixins/single/app-bar-menu";

export default mixins(MixinsPage, MixinsStorage, MixinsAppBarMenu).extend({
  name: "ProfileView",
  components: {},
  data: () => ({
    storageItems: {
      type: "profile-view",
    },
    dateFormatPattern: "YYYY년 MM월 DD일",
    profile: null as ProfileModel | null,
  }),
  mounted() {
    this.$nextTick(async () => {
      const params = this.$route.params;
      //console.log("query : ", query);
      if (this.isBlank(params.id)) {
        await this.notFound();
        return;
      }
      const id = Number(params.id);
      await this.loadData(id);
    });
  },
  methods: {
    appBarMenuEvent(menu: AppBarMenuItem) {
      if (menu.id === "favorite") {
        this.changeIconSubMenu();
        this.submit();
      } else {
        console.log("unknown id : ", menu.id);
      }
    },
    async loadData(id: number) {
      try {
        const profile = (this.profile = await ProfileService.get(id)) as ProfileModel;
        await this.changedProfile();
      } catch (error) {
        const e = error as ErrorResponse;
        if (e.status == 404) {
          // 프로필 정보 존재하지 않는다면 스토리지 저장된 프로필 삭제
          await this.storageWait();
          const items = this.getStorageItems();
          // console.log("items : ", items);
          if (items.list == null) {
            items.list = [] as StorageProfileView[];
          }
          const list = items.list;

          // 동일 ID 삭제
          list.some((item: StorageProfileView, index) => {
            if (item.profile.id === id) {
              list.splice(index, 1);
              return true;
            }
          });
          this.saveStorageItems();
        }
        console.log(e);
        await this.notFound();
        return;
      }
    },
    async changedProfile() {
      if (this.profile == null) {
        return;
      }
      const profile = this.profile;
      const id = profile.id;
      //console.log("profile : ", profile);

      // 조회한 프로필 저장
      await this.storageWait();
      const items = this.getStorageItems();
      // console.log("items : ", items);
      if (items.list == null) {
        items.list = [] as StorageProfileView[];
      }
      try {
        const list = items.list;

        // 동일 ID 삭제
        list.some((item: StorageProfileView, index) => {
          if (item.profile.id === id) {
            list.splice(index, 1);
            return true;
          }
        });
        // 20개 이상일경우
        const diffLength = list.length - 19;
        if (diffLength > 0) {
          for (let i = 0; i < diffLength; i++) {
            list.splice(list.length - 1, 1);
          }
        }

        list.unshift({
          profile: profile,
          date: new Date().getTime(),
        } as StorageProfileView);
        this.saveStorageItems();
      } catch (e) {
        console.log(e);
      }

      this.changeIconSubMenu();
    },
    getMailLink(email) {
      return core.mobile.getMailLink(email);
    },
    getTelLink(phone) {
      return core.mobile.getTelLink(phone);
    },
    changeIconSubMenu() {
      if (this.profile != null) {
        if (this.profile.favorite) {
          this.appBarChangeMenuIcon("favorite", "mdi-star");
        } else {
          this.appBarChangeMenuIcon("favorite", "mdi-star-outline");
        }
      } else {
        this.appBarChangeMenuIcon("favorite", "mdi-star-outline");
      }
    },
    async submit() {
      try {
        core.loader.show();
        if (this.profile) {
          if (this.profile.favorite) {
            await FavoriteService.delete(this.profile.id);
            this.profile.favorite = false;
          } else {
            await FavoriteService.create({ profileId: this.profile.id });
            this.profile.favorite = true;
          }
          await this.changedProfile();
          this.updatePreVmEvent(UpdateEventType.UPDATE, this.profile);
          // await this.loadData(this.profile.id);
        }
      } catch (e) {
        console.log(e);
      } finally {
        core.loader.hide();
      }
    },
  },
});
