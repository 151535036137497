
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import MixinsTable from "@/mixins/single/table";
import MixinsScroll from "@/mixins/single/scroll";
import { AppBarMenuItem, ErrorResponse, UpdateEventType } from "@/types";
import core from "@/core";
import CategoryService from "@/services/category/category.service";
import { CategoryModel, Type as CategoryType } from "@/models/category/category.model";
import UpdateEvent from "@/models";
import TextFieldModal from "@/modals/core/TextFieldModal.vue";
import MixinsAppBarMenu from "@/mixins/single/app-bar-menu";

export default mixins(MixinsPageForm, MixinsAppBarMenu, MixinsTable, MixinsScroll).extend({
  name: "MgmtCategory",
  components: { TextFieldModal },

  data: () => ({
    type: CategoryType.JOB_GROUP,
    typeName: "",
    table: {
      body: document.documentElement,
      defaultColumns: [],
      firstLoading: true,
      loading: false,
    },
    searchColumns: {
      type: "",
    },
    visible: {
      addBtn: false,
    },
    modal: {
      addEdit: {
        visible: false,
        params: {
          length: {
            min: 1,
            max: 100,
          },
          type: "add",
          title: "학력",
          enableDeleteButton: false,
          text: "",
          item: null as any,
          confirmButtonText: "추가",
          errorMessage: "",
        },
        updateEvent: null as UpdateEvent | null,
      },
    },
  }),
  mounted() {
    this.init();

    // 테이블 초기화
    this.initTable({
      service: CategoryService,
      serviceFunctionKey: "getTable",
      itemHeight: 40,
    });

    // 페이지 로딩 후 호출
    this.$nextTick(async () => {
      // 테이블 정보 가져오기
      this.loadTable(this.searchColumns);
    });
  },
  watch: {
    "scroll.scrollTop"(scrollTop) {
      this.scrollTop = scrollTop;
    },
    "modal.addEdit.updateEvent"() {
      const event = this.getComponentUpdateEvent(this.modal.addEdit);
      if (event != null) {
        if (event.result === UpdateEventType.CONFIRM) {
          const name = event.item as string;
          this.createOrUpdate(name);
          // console.log("content : ", content);
        } else if (event.result === UpdateEventType.DELETE) {
          const name = event.item as string;
          this.delete();
          // console.log("content : ", content);
        }
      }
    },
  },
  activated() {
    this.init();

    const event = this.getPageUpdateEvent();
    if (event != null) {
      if (event.result === UpdateEventType.UPDATE) {
        if (event.path.indexOf("search") > -1) {
          this.updateTableItem("id", event.item);
        } else {
          this.addTableItem("id", event.item);
        }
      } else if (event.result === UpdateEventType.DELETE) {
        this.deleteTableItem("id", event.item);
      } else {
        console.log("unknown event : ", event);
      }
    }
  },
  methods: {
    appBarMenuEvent(menu: AppBarMenuItem) {
      if (menu.id === "search") {
        this.$router.push({
          path: `/mgmt/category/${this.type.toLowerCase()}/search`,
        });
      } else {
        console.log("unknown id : ", menu.id);
      }
    },
    init() {
      const type = this.$route.params.type.toUpperCase();
      if (type === CategoryType.JOB_GROUP) {
        this.type = CategoryType.JOB_GROUP;
        this.typeName = "직군";
        this.visible.addBtn = true;
        this.$store.dispatch("topToolbar/changeTitle", { title: "직군 관리", ignoreCheck: true });
      } else if (type === CategoryType.PROFESSIONAL_FIELD) {
        this.type = CategoryType.PROFESSIONAL_FIELD;
        this.typeName = "전문분야";
        this.visible.addBtn = true;
        this.$store.dispatch("topToolbar/changeTitle", {
          title: "전문분야 관리",
          ignoreCheck: true,
        });
      } else {
        this.notFound();
        return;
      }

      this.searchColumns.type = type;
    },
    addItem() {
      const modal = this.modal.addEdit;
      modal.params.errorMessage = "";
      modal.params.type = "add";
      modal.params.title = this.typeName;
      modal.params.enableDeleteButton = false;
      modal.params.confirmButtonText = "추가";
      modal.params.item = null;
      modal.params.text = "";
      modal.visible = true;
    },
    editItem(item: CategoryModel) {
      const modal = this.modal.addEdit;
      modal.params.errorMessage = "";
      modal.params.type = "edit";
      modal.params.title = this.typeName;
      modal.params.enableDeleteButton = true;
      modal.params.confirmButtonText = "수정";
      modal.params.item = item;
      modal.params.text = item.name;
      modal.visible = true;
    },
    async delete() {
      const modal = this.modal.addEdit;
      try {
        const id = modal.params.item.id;
        await CategoryService.delete(id);
        // console.log("item : ", item);
        this.deleteTableItem("id", modal.params.item);
      } catch (e) {
        // console.log(e);
        core.alert.hide();
        const error = e as ErrorResponse;
        modal.params.errorMessage = error.message;
        modal.visible = true;
      }
    },
    async createOrUpdate(name: string) {
      const modal = this.modal.addEdit;
      if (modal.params.type === "add") {
        try {
          const params = {
            type: this.type,
            name: name,
          };
          const item = await CategoryService.create(params);
          this.addTableItem("id", item);
        } catch (e) {
          // console.log(e);
          core.alert.hide();
          const error = e as ErrorResponse;
          modal.params.errorMessage = error.message;
          modal.params.text = name;
          modal.visible = true;
        }
      } else if (modal.params.type === "edit") {
        try {
          const id = modal.params.item.id;
          const params = {
            name: name,
          };
          const item = await CategoryService.update(id, params);
          this.addTableItem("id", item);
        } catch (e) {
          // console.log(e);
          core.alert.hide();
          const error = e as ErrorResponse;
          modal.params.errorMessage = error.message;
          modal.params.text = name;
          modal.visible = true;
        }
      } else {
        console.log("unknown type : ", modal.params.type);
      }
    },
  },
});
