
import mixins from "vue-typed-mixins";
import MixinsScroll, { ScrollPosition } from "@/mixins/single/body-scroll";

export interface ScrollControl {
  top: number;
  position: null | ScrollPosition;
  updatedPosition: null | ScrollPosition;
}

export default mixins(MixinsScroll).extend({
  name: "SccComponentTemplate",
  props: {
    touch: {
      type: Object,
      default: null,
    },
    scrollControl: {
      type: Object,
      default: () => {
        return {
          top: 0,
          position: null as ScrollPosition | null,
          updatedPosition: null as ScrollPosition | null,
        } as ScrollControl;
      },
    },
  },
  data: () => ({}),
  updated() {
    const updatedPosition = this.scrollControl.updatedPosition;
    if (updatedPosition != null) {
      this.scrollControl.updatedPosition = null;
      this.scrollControl.position = updatedPosition;
    }
    this.$emit("update:updated", true);
  },
  watch: {
    "scroll.scrollTop"(scrollTop) {
      this.$emit("update:scrollTop", scrollTop);
      const height = this.scroll.scrollHeight - this.scroll.clientHeight;
      this.$emit("update:scrollPercentage", (scrollTop / height) * 100);
    },
    "scroll.scrollHeight"(scrollHeight) {
      this.$emit("update:scrollHeight", scrollHeight);
      const height = this.scroll.scrollHeight - this.scroll.clientHeight;
      this.$emit("update:scrollPercentage", (this.scroll.scrollTop / height) * 100);
    },
    "scroll.clientHeight"(clientHeight) {
      this.$emit("update:clientHeight", clientHeight);
      const height = this.scroll.scrollHeight - this.scroll.clientHeight;
      this.$emit("update:scrollPercentage", (this.scroll.scrollTop / height) * 100);
    },
    "scrollControl.top"(val) {
      // console.log("changed scroll control : ", val);
      this.scrollControl.top = null;
      if (val != null) {
        this.setScrollTop(Number(val));
      }
    },
    "scrollControl.position"(val) {
      //console.log("changed scroll position : ", val);
      this.scrollControl.position = null;
      if (val != null) {
        this.setScrollPosition(val);
      }
    },
  },
});
