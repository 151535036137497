
import mixins from "vue-typed-mixins";
import store from "@/store";
import MixinsPageForm from "@/mixins/page-form";
import core from "@/core";

export default mixins(MixinsPageForm).extend({
  name: "Login",

  data: () => ({
    app: store.state.app,
    processing: false,
    form: {
      rememberMe: true,
      username: "",
      password: "",
    },
    loading: {
      kakao: false,
    },
    formConvertField: {
      username: "user_username",
      password: "user_password",
    },
  }),

  mounted() {
    // 페이지 로딩 후 호출
    this.$nextTick(async () => {
      if (!this.app.isMobile) {
        this.form.rememberMe = false;
      }

      // const query = this.$route.query;
      // if (core.utils.validate.isNotBlank(query.result)) {
      //   this.replaceState();
      //   // 소셜 로그인
      //   if (query.result === "error") {
      //     const message = query.message as string;
      //     await core.alert.show({
      //       title: "알림",
      //       body: message,
      //     });
      //   } else if (query.result === "success") {
      //     const token = core.utils.getCookie("SOCIAL-TOKEN") as string;
      //     const result = await UserService.getSocialToken(token);
      //     core.utils.deleteCookie("SOCIAL-TOKEN");
      //     (window as any).socialLogin(JSON.stringify(result));
      //   }
      // }
    });
  },
  methods: {
    moveRegisterPage() {
      // /register
      this.$router.push({
        path: "/register",
        query: this.$route.query,
      });
    },
    async submit() {
      if (await this.validate()) {
        this.processing = true;

        try {
          const user = await this.$store.dispatch("auth/login", this.form);
          if (user != null) {
            await this.$router.push("/");
          }
        } catch (e) {
          // console.log(e);
          this.errorSubmit(e, this.formConvertField);
        }
        this.processing = false;
      }
    },
  },
});
