export enum Type {
  JOB_GROUP = "JG", // 직군
  PROFESSIONAL_FIELD = "PF", // 전문분야
}

export interface CategoryModel {
  id: number;
  type: Type;
  name: string;
}

export interface SimpleCategoryModel {
  id: number;
  name: string;
}
