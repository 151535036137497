import core from "@/core";
import AbstractTableService from "@/services/core/abstract-table.service";
import { UserModel } from "@/models/user/user.model";
import { UserProfileModel } from "@/models/user/user-profile.model";

class UserMgmtService extends AbstractTableService<UserModel> {
  constructor() {
    super("/api/v1/user", "user");
  }

  updateRole(id: number, role): Promise<UserModel> {
    const strParam = JSON.stringify({
      role: role,
    });
    return new Promise((resolve, reject) => {
      core.http
        .putJson(`/api/v1/user/${id}/role`, strParam)
        .then((data) => {
          //console.log('result: ', data);
          if (data[this.itemKey]) {
            resolve(data[this.itemKey]);
          } else {
            reject({ message: "not found item" });
          }
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  getProfile(id: number): Promise<UserProfileModel> {
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`/api/v1/user/${id}/profile`)
        .then((data) => {
          //console.log('result: ', data);
          if (data["profile"]) {
            resolve(data["profile"]);
          } else {
            reject({ message: "not found item" });
          }
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }
  updateProfile(id: number, param: any): Promise<UserProfileModel> {
    const strParam = JSON.stringify(param);
    return new Promise((resolve, reject) => {
      core.http
        .putJson(`/api/v1/user/${id}/profile`, strParam)
        .then((data) => {
          if (data.profile != null) {
            resolve(data.profile as UserProfileModel);
          } else {
            reject({ message: "not found item" });
          }
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }
}

export default new UserMgmtService();
