import core from "@/core";
import AbstractCoreService from "@/services/core/abstract-core.service";

abstract class AbstractCrudService<T> extends AbstractCoreService<T> {
  get(id: number, config?: any, params?: any, replaceParams?: [string]): Promise<T> {
    const url = this.getUrl(replaceParams);
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`${url}/${id}`, config, params)
        .then((data) => {
          if (typeof data === "object") {
            const item = data[this.itemKey];
            if (item != null) {
              resolve(item);
            } else {
              reject({
                message: "not found item",
              });
            }
          } else {
            reject({
              message: "data type error",
            });
          }
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  getList(replaceParams?: [string], params?: any): Promise<T[]> {
    const url = this.getUrl(replaceParams);
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`${url}`, null, params)
        .then((data) => {
          if (typeof data === "object") {
            const item = data[this.itemKey + "List"];
            if (item != null) {
              resolve(item);
            } else {
              reject({
                message: "not found item",
              });
            }
          } else {
            reject({
              message: "data type error",
            });
          }
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  create(params: any, replaceParams?: [string]): Promise<T> {
    const url = this.getUrl(replaceParams);
    const strParams = JSON.stringify(params);
    return new Promise((resolve, reject) => {
      core.http
        .postJson(`${url}`, strParams)
        .then((data) => {
          if (typeof data === "object") {
            const item = data[this.itemKey];
            if (item != null) {
              resolve(item);
            } else {
              reject({
                message: "not found item",
              });
            }
          } else {
            reject({
              message: "data type error",
            });
          }
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  update(id: number, params: any, replaceParams?: [string]): Promise<T> {
    const url = this.getUrl(replaceParams);
    return new Promise((resolve, reject) => {
      const strJson = JSON.stringify(params);
      core.http
        .putJson(`${url}/${id}`, strJson)
        .then((data) => {
          if (typeof data === "object") {
            const item = data[this.itemKey];
            if (item != null) {
              resolve(item);
            } else {
              reject({
                message: "not found item",
              });
            }
          } else {
            reject({
              message: "data type error",
            });
          }
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  delete(id: number, params?: any, replaceParams?: [string]): Promise<any> {
    const url = this.getUrl(replaceParams);
    return new Promise((resolve, reject) => {
      const fullUrl = `${url}/${id}${params == null ? "" : "?" + core.http.objToUrlParams(params)}`;
      core.http
        .delete(`${fullUrl}`, null)
        .then((data) => {
          resolve(data);
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }
}

export default AbstractCrudService;
