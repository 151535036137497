import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import NotSupportBrowser from "@/views/errors/NotSupportBrowser.vue";
import NotFound from "@/views/errors/NotFound.vue";
import Home from "@/views/Home.vue";
import Logout from "@/views/Logout.vue";
import core from "@/core";
import store from "@/store";
import Login from "@/views/Login.vue";
import Constant from "@/store/constant";
import Register from "@/views/Register.vue";
import FindUsername from "@/views/FindUsername.vue";
import FindPassword from "@/views/FindPassword.vue";
import { Role } from "@/models/user/user.model";
import Directory from "@/views/Directory.vue";
import { AppBarMenu } from "@/types";
import ProfileView from "@/views/profile/ProfileView.vue";
import ProfileSearch from "@/views/profile/ProfileSearch.vue";
import Favorite from "@/views/Favorite.vue";
import FavoriteSearch from "@/views/favorite/FavoriteSearch.vue";
import MgmtCategory from "@/views/mgmt/Category.vue";
import MgmtCategorySearch from "@/views/mgmt/CategorySearch.vue";
import MgmtUser from "@/views/mgmt/User.vue";
import MgmtUserSearch from "@/views/mgmt/UserSearch.vue";
import MgmtUserView from "@/views/mgmt/UserView.vue";
import UserProfileView from "@/views/mgmt/UserProfileView.vue";
import UserProfileEdit from "@/views/mgmt/UserProfileEdit.vue";
import Settings from "@/views/Settings.vue";
import Notification from "@/views/Notification.vue";
import UserMyInfoView from "@/views/user/MyInfoView.vue";
import UserMyInfoEdit from "@/views/user/MyInfoEdit.vue";
import UserMyPassword from "@/views/user/MyPassword.vue";
import UserMyWithdraw from "@/views/user/MyWithdraw.vue";
import UserMyProfile from "@/views/user/MyProfile.vue";
import UserWaitApproval from "@/views/user/WaitApproval.vue";

Vue.use(VueRouter);

const appName = Constant.appName;

export enum LayoutType {
  NAV = "nav",
  APP_BAR = "app_bar",
  APP_BAR_NAV = "app_bar_nav",
  APP_BAR_BACK = "app_bar_back",
  APP_BOTTOM_BAR = "app_bottom_bar",
}

export interface RouteMeta {
  title: string;
  roles: Role[] | null;
  layoutList: LayoutType[];
  pageStack: boolean;
  appBarMenu: AppBarMenu | null | undefined;
  scrollable: boolean | undefined;
}

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: "/home",
    meta: {
      title: "",
      roles: null,
      layoutList: [],
      pageStack: false,
      appBarMenu: null,
      scrollable: true,
    } as RouteMeta,
  },
  {
    path: "/home",
    name: "home",
    component: Home,
    meta: {
      title: "홈",
      roles: [Role.ADMIN, Role.MANAGER, Role.USER],
      layoutList: [LayoutType.NAV, LayoutType.APP_BOTTOM_BAR] as LayoutType[],
      pageStack: false,
      appBarMenu: null,
      scrollable: true,
    } as RouteMeta,
  },
  {
    path: "/register",
    name: "register",
    component: Register,
    meta: {
      title: "회원가입",
      layoutList: [LayoutType.APP_BAR_BACK] as LayoutType[],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      title: "로그인",
      layoutList: [] as LayoutType[],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/find-username",
    name: "findUsername",
    component: FindUsername,
    meta: {
      title: "사용자 아이디 찾기",
      layoutList: [LayoutType.APP_BAR_BACK] as LayoutType[],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/find-password",
    name: "findPassword",
    component: FindPassword,
    meta: {
      title: "사용자 비밀번호 찾기",
      layoutList: [LayoutType.APP_BAR_BACK] as LayoutType[],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/logout",
    name: "logout",
    component: Logout,
    meta: {
      title: "로그아웃",
      roles: [Role.ADMIN, Role.MANAGER, Role.USER, Role.WAIT_USER],
      layoutList: [] as LayoutType[],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/error/not-support-browser",
    name: "notSupportBrowser",
    component: NotSupportBrowser,
    meta: {
      title: "",
      roles: null,
      layoutList: [] as LayoutType[],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "*",
    name: "notFound",
    component: NotFound,
    meta: {
      title: "페이지를 찾을 수 없습니다",
      layoutList: [LayoutType.APP_BAR_BACK] as LayoutType[],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/notification",
    name: "notification",
    component: Notification,
    meta: {
      title: "알림",
      roles: [Role.ADMIN, Role.MANAGER, Role.USER, Role.WAIT_USER],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR, LayoutType.APP_BOTTOM_BAR] as LayoutType[],
      pageStack: false,
      // appBarMenu: {
      //   icon: {
      //     visible: true,
      //     list: [
      //       {
      //         icon: "mdi-filter-variant",
      //         text: "필터",
      //         id: "filter",
      //       },
      //     ],
      //   },
      // },
    } as RouteMeta,
  },
  {
    path: "/settings",
    name: "settings",
    component: Settings,
    meta: {
      title: "설정",
      roles: [Role.ADMIN, Role.MANAGER, Role.USER],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR, LayoutType.APP_BOTTOM_BAR] as LayoutType[],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/user/wait-approval",
    name: "userWaitApproval",
    component: UserWaitApproval,
    meta: {
      title: "승인대기",
      roles: [Role.WAIT_USER],
      layoutList: [LayoutType.APP_BAR] as LayoutType[],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/user/me/info",
    name: "userMyInfoView",
    component: UserMyInfoView,
    meta: {
      title: "계정정보",
      roles: [Role.ADMIN, Role.MANAGER, Role.USER],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK] as LayoutType[],
      pageStack: false,
      appBarMenu: {
        icon: {
          visible: true,
          list: [
            {
              text: "수정",
              id: "edit",
            },
          ],
        },
      },
    } as RouteMeta,
  },
  {
    path: "/user/me/info/edit",
    name: "userMyInfoEdit",
    component: UserMyInfoEdit,
    meta: {
      title: "계정정보",
      roles: [Role.ADMIN, Role.MANAGER, Role.USER],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK] as LayoutType[],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/user/me/password",
    name: "userMyPassword",
    component: UserMyPassword,
    meta: {
      title: "비밀번호 변경",
      roles: [Role.ADMIN, Role.MANAGER, Role.USER, Role.WAIT_USER],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK] as LayoutType[],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/user/me/withdraw",
    name: "userMyWithdraw",
    component: UserMyWithdraw,
    meta: {
      title: "회원탈퇴",
      roles: [Role.ADMIN, Role.MANAGER, Role.USER, Role.WAIT_USER],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK] as LayoutType[],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/user/me/profile",
    name: "userMyProfile",
    component: UserMyProfile,
    meta: {
      title: "프로필",
      roles: [Role.ADMIN, Role.MANAGER, Role.USER, Role.WAIT_USER],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK] as LayoutType[],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/mgmt/user",
    name: "mgmtUser",
    component: MgmtUser,
    meta: {
      title: "사용자 관리",
      roles: [Role.ADMIN, Role.MANAGER],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK] as LayoutType[],
      pageStack: true,
      appBarMenu: {
        icon: {
          visible: true,
          list: [
            {
              icon: "mdi-magnify",
              text: "검색",
              id: "search",
              visible: true,
            },
          ],
        },
      },
    } as RouteMeta,
  },
  {
    path: "/mgmt/user/search",
    name: "mgmtUserSearch",
    component: MgmtUserSearch,
    meta: {
      title: "사용자 검색",
      roles: [Role.ADMIN, Role.MANAGER],
      layoutList: [LayoutType.NAV] as LayoutType[],
      pageStack: true,
    } as RouteMeta,
  },
  {
    path: "/mgmt/user/:id(\\d+)",
    name: "mgmtUserView",
    component: MgmtUserView,
    meta: {
      title: "사용자",
      roles: [Role.ADMIN, Role.MANAGER],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK] as LayoutType[],
      pageStack: false,
      appBarMenu: {
        icon: {
          visible: true,
          list: [
            {
              text: "삭제",
              id: "delete",
              visible: false,
            },
          ],
        },
      },
    } as RouteMeta,
  },
  {
    path: "/mgmt/user/:id(\\d+)/profile",
    name: "mgmtUserProfileView",
    component: UserProfileView,
    meta: {
      title: "사용자 프로필",
      roles: [Role.ADMIN, Role.MANAGER],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK] as LayoutType[],
      pageStack: false,
      appBarMenu: {
        icon: {
          visible: true,
          list: [
            {
              text: "수정",
              id: "edit",
              visible: false,
            },
          ],
        },
      },
    } as RouteMeta,
  },
  {
    path: "/mgmt/user/:id(\\d+)/profile/edit",
    name: "mgmtUserProfileEdit",
    component: UserProfileEdit,
    meta: {
      title: "사용자 프로필 수정",
      roles: [Role.ADMIN, Role.MANAGER],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK] as LayoutType[],
    } as RouteMeta,
  },
  {
    path: "/mgmt/category/:type(jg|pf)",
    name: "mgmtCategory",
    component: MgmtCategory,
    meta: {
      title: "관리",
      roles: [Role.ADMIN, Role.MANAGER],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK] as LayoutType[],
      pageStack: true,
      appBarMenu: {
        icon: {
          visible: true,
          list: [
            {
              icon: "mdi-magnify",
              text: "검색",
              id: "search",
              visible: true,
            },
          ],
        },
      },
    } as RouteMeta,
  },
  {
    path: "/mgmt/category/:type(jg|pf)/search",
    name: "mgmtCategorySearch",
    component: MgmtCategorySearch,
    meta: {
      title: "검색",
      roles: [Role.ADMIN, Role.MANAGER],
      layoutList: [LayoutType.NAV] as LayoutType[],
      pageStack: true,
    } as RouteMeta,
  },
  {
    path: "/directory",
    name: "directory",
    component: Directory,
    meta: {
      title: "인명록",
      roles: [Role.ADMIN, Role.MANAGER, Role.USER],
      layoutList: [LayoutType.NAV, LayoutType.APP_BOTTOM_BAR] as LayoutType[],
      pageStack: true,
      appBarMenu: {
        icon: {
          visible: true,
          list: [
            {
              icon: "mdi-magnify",
              text: "검색",
              id: "search",
              visible: true,
            },
          ],
        },
      },
    } as RouteMeta,
  },
  {
    path: "/profile/:id(\\d+)",
    name: "profileView",
    component: ProfileView,
    meta: {
      title: "프로필",
      roles: [Role.ADMIN, Role.MANAGER, Role.USER],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK] as LayoutType[],
      pageStack: false,
      appBarMenu: {
        icon: {
          visible: true,
          list: [
            {
              icon: "mdi-star-outline",
              text: "즐겨찾기",
              id: "favorite",
              visible: true,
            },
          ],
        },
      },
    } as RouteMeta,
  },
  {
    path: "/profile/search",
    name: "profileSearch",
    component: ProfileSearch,
    meta: {
      title: "프로필 검색",
      roles: [Role.ADMIN, Role.MANAGER, Role.USER],
      layoutList: [LayoutType.NAV] as LayoutType[],
      pageStack: true,
    } as RouteMeta,
  },
  {
    path: "/favorite",
    name: "favorite",
    component: Favorite,
    meta: {
      title: "즐겨찾기",
      roles: [Role.ADMIN, Role.MANAGER, Role.USER],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR, LayoutType.APP_BOTTOM_BAR] as LayoutType[],
      pageStack: true,
      appBarMenu: {
        icon: {
          visible: true,
          list: [
            {
              icon: "mdi-magnify",
              text: "검색",
              id: "search",
              visible: true,
            },
          ],
        },
      },
    } as RouteMeta,
  },
  {
    path: "/favorite/search",
    name: "favoriteSearch",
    component: FavoriteSearch,
    meta: {
      title: "즐겨찾기 검색",
      roles: [Role.ADMIN, Role.MANAGER, Role.USER],
      layoutList: [LayoutType.NAV] as LayoutType[],
      pageStack: true,
    } as RouteMeta,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const routeMap = new Map();
routes.forEach((data) => {
  if (data.children != null) {
    const path = data.path;
    data.children.forEach((data) => {
      routeMap.set(path + data.path, data);
    });
  } else {
    routeMap.set(data.path, data);
  }

  if (data.meta != null) {
    // appBarMenu 초기값 설정
    const appBarMenu = (data.meta as RouteMeta).appBarMenu;
    if (appBarMenu) {
      ["icon", "more"].forEach((key) => {
        if (appBarMenu[key]) {
          const list = appBarMenu[key].list;
          if (list != null) {
            list.forEach((menu) => {
              [
                { key: "visible", value: true },
                { key: "clickEvent", value: false },
              ].forEach((defaultColumn) => {
                if (menu[defaultColumn.key] == undefined) {
                  menu[defaultColumn.key] = defaultColumn.value;
                }
              });
            });
          }
        }
      });
    }
  }
});

async function routerResult(next, path: string) {
  next({ path: path });
  const route = routeMap.get(path);
  // console.log("routerResult path : ", path);
  if (route) {
    return route.meta;
  }
  return "";
}

async function beforeEach(to, from, next) {
  const user = await store.getters["auth/user"]();
  if (user) {
    // 로그인 중인 사용자
    if (to.meta.roles != null && to.meta.roles.length > 0) {
      let accessible = false;
      user.roles.some((role) => {
        if (to.meta.roles.indexOf(role) > -1) {
          accessible = true;
          return true;
        }
      });

      if (accessible) {
        next();
      } else {
        // 접근 불가 처리
        if (user.roles.indexOf(Role.WAIT_USER) > -1) {
          const profile = await store.getters["auth/profile"]();
          // console.log("profile : ", profile);
          // 승인 대기중인 사용자일 경우
          return await routerResult(next, "/user/wait-approval");
        } else {
          return await routerResult(next, "/not-found");
        }
      }
    } else {
      // 비로그인 사용자 접근 가능 페이지일 경우 이동
      if (to.path.toLowerCase() === "/login") {
        if (core.utils.validate.isBlank(to.query.v)) {
          // 로그인중인 사용자가 로그인 페이지 접근시 이동
          return await routerResult(next, "/home");
        } else {
          // 로그인중인 사용자가 자동인증 정보파라미터를 포함해서 들어왔을 경우 로그인 페이지로 이동
          next();
        }
      } else {
        next();
      }
      // console.log("로그인 사용자 next, 페이지 권한 필요없음");
    }
  } else {
    if (to.meta.roles != null && to.meta.roles.length > 0) {
      // 로그인 사용자 접근 가능 페이지일 경우 메인 페이지로 이동
      return await routerResult(next, "/login");
    } else {
      next();
      // console.log("비로그인 사용자 next, 페이지 권한 필요없음");
    }
  }

  return to.meta;
}

let first = true;
let overflow = true;
router.beforeEach(async (to, from, next) => {
  // console.log("from   : ", from);
  // console.log("to     : ", to);
  //console.log("from " + from.path + " -> to : " + to.path);
  if (first) {
    first = false;
    core.loader.show();
  }
  if (store.state.topToolbar.changedStyle) {
    await store.dispatch("topToolbar/changeOrgStyle");
  }

  if (core.toast.isShow()) {
    core.toast.hide();
  }

  if (
    (from.path === "/home" && to.path === "/login") ||
    (from.path === "/login" && to.path === "/login")
  ) {
    window.history.back();
    return;
  }

  const meta = await beforeEach(to, from, next);
  if (meta != null) {
    if (meta.title != null) {
      if (meta.title.length > 0) {
        document.title = `${appName} - ${meta.title}`;
      } else {
        document.title = appName;
      }
    }

    await store.dispatch("topToolbar/changeTitle", { title: meta.title, ignoreCheck: false });

    if (meta.scrollable !== undefined) {
      if (meta.scrollable) {
        overflow = true;
        document.documentElement.classList.remove("scc-scrollbar-hidden");
      } else {
        overflow = false;
        document.documentElement.classList.add("scc-scrollbar-hidden");
      }
    } else if (!overflow) {
      document.documentElement.classList.remove("scc-scrollbar-hidden");
    }
  }
  core.loader.hide();
});

export default router;
