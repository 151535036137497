abstract class AbstractCoreService<T> {
  protected readonly url: string;
  protected readonly itemKey: string;
  protected readonly replaceParams?: [string];

  protected constructor(url: string, itemKey: string, replaceParams?: [string]) {
    this.url = url;
    this.itemKey = itemKey;
    this.replaceParams = replaceParams;
  }

  getUrl(replaceParams?: [string]) {
    let url = this.url;
    if (replaceParams != null && this.replaceParams != null) {
      const length = this.replaceParams.length;
      for (let i = 0; i < length; i++) {
        const param = this.replaceParams[i];
        const replace = replaceParams[i];
        url = url.replace(param, replace);
      }
    }
    return url;
  }
}

export default AbstractCoreService;
